<template>
  <div :class="wrapperClass">
    <div>{{ displayText }}</div>
    <div class="globalSnackbarMessageClose">
      <IconTooltip
        v-if="isCloseIconVisible"
        icon="fas fa-close close"
        tooltip="Close"
        @click="removeGlobalSnackbarMessage"
      />
    </div>
  </div>
</template>
<script setup>
import { toRefs } from "vue";
import IconTooltip from "@/components/common/IconTooltip.vue";

/**
 * message = {
 *    messageType: String,
 *    displayText: String,
 *    showCloseButton: Boolean,
 *    id: String,
 * }
 */
const props = defineProps({
  message: Object,
});
const emit = defineEmits(["onRemoveSnackbarMessage"]);
const { message } = toRefs(props);
const {
  id,
  displayText,
  messageType,
  timeout = 0,
  showCloseButton,
} = message.value;
const isCloseIconVisible = timeout > 0 || showCloseButton;
const wrapperClass = `globalSnackbarMessageWrapper ${messageType}SnackbarMessage`;
const removeGlobalSnackbarMessage = () => {
  emit("onRemoveSnackbarMessage", id);
};
</script>

<style>
.globalSnackbarMessageWrapper {
  display: grid;
  grid-template-columns: 1fr 32px;
  padding: 8px;
  grid-gap: 8px;
  color: #000;
  border-radius: 8px;
  font-size: 15.75px;
  font-weight: 400;
  letter-spacing: 0.315px;
  line-height: 22.5px;
}

.globalSnackbarMessageClose {
  display: grid;
  justify-content: end;
  align-items: start;
  font-size: 20px;
}

.successSnackbarMessage {
  background-color: rgb(2, 153, 73);
  border: solid 1px #2d640e;
  color: #fff;
}

.warningSnackbarMessage {
  background-color: rgb(224, 171, 6);
  border: solid 1px #8b6c0d;
}

.errorSnackbarMessage {
  background-color: rgb(215, 26, 23);
  border: solid 1px #840d09;
  color: #fff;
}

.error_customSnackbarMessage {
  background-color: #ff0000;
  border: solid 1px #840d09;
  color: #000;
}
</style>
