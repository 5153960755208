import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import VueGtag from "vue-gtag-next";
import { createPinia } from "pinia";
import { vMaska } from "maska/vue";
import { useConfigStore } from "@/stores/config";
import { useConnectivityStore } from "@/stores/connectivity";
import auth from "@/auth";
import { useUserStore } from "@/stores/userStore";
import axios from "axios";
import { useStructureIDBStore } from "@/stores/structureIDB";
import { useAdminStore } from "@/stores/admin";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import axiosRetry from "axios-retry";
import { useUserPermissionStore } from "@/stores/userPermission";
loadFonts();

const app = createApp(App);
app.use(createPinia());
const userStore = useUserStore();
const connectivity = useConnectivityStore();
const userPermissionStore = useUserPermissionStore();
function init() {
  app
    .use(router)
    .use(vuetify)
    .use(VueGtag, {
      config: { id: "G-4P7XKRBH5M" },
      useDebugger: import.meta.env.DEV,
    });
  app.directive("maska", vMaska);
}

const loadConfigs = async (configStore) => {
  const isPersisted = await navigator?.storage?.persisted();
  if (!isPersisted) {
    await navigator?.storage?.persist();
  }
  const user = await auth.getUser();
  Promise.all([
    configStore.loadUsers(user),
    configStore.loadNbisBridgeInspectors(user),
    configStore.loadNbisNSTMBridgeInspectors(user),
    configStore.loadNbisTunnelInspectors(user),
    configStore.loadInspectors(user),
    configStore.loadUnderwaterDivers(user),
    configStore.loadReferenceData(user),
    configStore.loadMetricConversions(user),
    configStore.loadDistricts(user),
    configStore.loadCounties(user),
    configStore.loadMunicipalities(user),
    configStore.loadScourCriticalBridgeIndicators(user),
    configStore.loadElementDefinitions(user),
    configStore.loadDepartmentStructureTypeDescriptions(user),
    configStore.loadElementChildren(user),
    configStore.loadElementStateDefinitions(user),
    configStore.loadDocTypes(user),
    configStore.loadFlexActions(user),
    configStore.loadBridgeActions(user),
    configStore.loadCodeFilters(user),
    configStore.loadOrganizations(user),
    configStore.loadSPNOrganizations(user),
    configStore.loadInspectionReportSection(user),
    userPermissionStore.getUserPermission(),
  ]).then(() => {
    app.mount("#app");
  });
};

async function authenticateUser() {
  init();
  await connectivity.getServiceStatus();
  if (!connectivity.getisOnline) {
    if (userStore.isAuthenticated) {
      router.push({ name: "ViewWorkList" });
    } else {
      router.push({ name: "OfflineLogin" });
    }
  } else {
    await auth.setToken();
    if (!userStore.isAuthenticated) {
      await auth.login();
    } else {
      addInterceptor();
      addRetryConfig();
    }
  }
}
authenticateUser().then(async () => {
  const user = await auth.getUser();
  const configStore = useConfigStore();
  await configStore.loadEnvProperties(user);
  await configStore.loadAllowedUsersEnvProperties(user);
  const isRestricted = Boolean(
    configStore.getEnvConfigValue(ENV_CONFIG_PROPERTY.ENABLE_USERS_RESTRICTION)
  );
  const userStore = useUserStore();
  const userid = userStore.loggedInUser?.sub;
  if (isRestricted && !configStore.isUserAllowed(userid)) {
    logout();
    return;
  }
  const isUserAllowedToAccessApp = await isRoleAllowed(
    configStore,
    userStore.getUserRoles()
  );
  if (!isUserAllowedToAccessApp) {
    logout();
    window.location.href = import.meta.env.VITE_APP_ESEC_ACCESS_DENIED_URI;
    return;
  }
  loadConfigs(configStore);
  if (connectivity.getisOnline) {
    await userStore.addUser();
    await storePass();
    const structureIDBStore = useStructureIDBStore();
    await structureIDBStore.initDB(false);
    await structureIDBStore.saveUserInfo(userStore?.loggedInUser);
  }
});

const addInterceptor = () => {
  axios.interceptors.request.use(async (config) => {
    const user = await auth.getUser();
    config.headers.Authorization = `Bearer ${user.id_token}`;
    return config;
  });
};

const onRetry = async (_, error) => {
  await new Promise((resolve, reject) => {
    if (error.code == "ERR_NETWORK") {
      reject(new Error("Skip retries when no internet connection"));
    } else {
      resolve();
    }
  });
};

const addRetryConfig = () => {
  axiosRetry(axios, {
    retries: 3,
    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000),
    onRetry: onRetry,
  });
};

const storePass = async () => {
  const adminStore = useAdminStore();
  const configStore = useConfigStore();

  const userId = userStore.getUserSub();

  const pouchUser = await adminStore.getUserInfo(userId);
  if (!pouchUser) {
    const defaultHash = configStore.getEnvConfigValue(
      ENV_CONFIG_PROPERTY.OFFLINE_DEFAULT_PASS
    );
    //get default password
    localStorage.setItem("hashedPass", defaultHash);
    adminStore.saveUser({ userId, passwordHash: defaultHash });
  } else {
    localStorage.setItem("hashedPass", pouchUser?.passwordHash);
  }
};

const logout = async () => {
  const userStore = useUserStore();
  const connectivity = useConnectivityStore();
  userStore.setUser(null);
  userStore.setIsAuthenticated(false);

  await connectivity.getServiceStatus();
  if (connectivity.getOnlineServiceStatus) {
    auth.logout();
    router.push({ name: "LogoutPage" });
  } else {
    router.push({ name: "OfflineLogin" });
  }
};

/**
 * isRoleAllowed return boolean value if user is part of the
 * allowed user group.
 * @param {object} configStore application store
 * @param {string[]} groups list of group user is part of.
 * @return boolean
 */
const isRoleAllowed = async (configStore, groups) => {
  await connectivity.getServiceStatus();
  if (!connectivity.getisOnline) {
    return true;
  }
  const isRoleRestrictionEnabled = Boolean(
    configStore.getEnvConfigValue(ENV_CONFIG_PROPERTY.ENABLE_ROLE_RESTRICTION)
  );

  if (isRoleRestrictionEnabled) {
    const allowedRoles = new Set();
    (configStore.getEnvConfigValue(ENV_CONFIG_PROPERTY.ALLOWED_ROLES) ?? "")
      .split(",")
      .forEach((role) => allowedRoles.add(role));
    return groups.length > 0 && groups.some((group) => allowedRoles.has(group));
  }

  // Hoping `isRoleRestrictionEnabled` will be always true.
  // if not, fallback to current behaviour (role check is not implemented).
  return true;
};
